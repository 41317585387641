import {createApp} from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import {adaptHtmlSize} from '@/utils/tools'
import config from './config'
import {Pagination, Modal, Tag, Dropdown, Menu, Button, Progress, Tabs, Input,Form,Upload,Image,Select} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './styles/theme.scss'
import moment from 'moment'


adaptHtmlSize(config.designPcWidth, 1024)

window.addEventListener('resize', function () {
    adaptHtmlSize(config.designPcWidth, 1024)
})

const app = createApp(App)

app.use(router)
app.use(store)

app.use(Pagination)
    .use(Modal)
    .use(Tag)
    .use(Dropdown)
    .use(Menu)
    .use(Button)
    .use(Progress)
    .use(Tabs)
    .use(Form)
    .use(Input)
    .use(Upload)
    .use(Image)
    .use(Select)



app.config.globalProperties.$fileType = {
    isVideo(aurl){
        let url = aurl??''.toLowerCase();
        if (url && (url.endsWith('.mp4') || url.endsWith('.avi'))
            || url.endsWith('.mtk')|| url.endsWith('.flv') || url.endsWith('.dat')
            || url.endsWith('.wmv') || url.endsWith('.mov') || url.endsWith('.m4v')
            || url.endsWith('.asf') || url.endsWith('.asx') || url.endsWith('.rm')
            || url.endsWith('.rmvb') || url.endsWith('.3gb')
        ){
            return true;
        }else {
            return false;
        }
    }
}

app.config.globalProperties.$date = {
    moment(date,formate = 'YYYY-MM-DD HH:mm'){
        return moment(date).format(formate)
    }
}


app.mount('#app')



