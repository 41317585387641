<template>
  <ul class="menu">
    <MenuItem
      v-for="(item, index) in list"
      :key="index"
      :item="item"
      :theme="theme"
      :isActive="active === item.path"
      :router="router"
      :isHome="isHome"
    />

  </ul>
</template>
<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import MenuItem from './menu-item'
export default {
  name: 'c-menu-item',
  components: {
    MenuItem
  },
  props: {
    theme: {
      type: String,
      default: 'light'
    },
    active: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    router: Object,
    isHome: {
      type:Boolean,
      default:true
    }
  },
  setup () {
    const router = useRouter()
    const name = ref('')
    const search = () => {
      router.push({
        path: '/towns',
        query: {
          name: name.value
        }
      })
      name.value = ''
    }
    return {
      name,
      search
    }
  }
}
</script>
<style lang="scss" scoped>
.menu {
  height: 100%;
  //width: 100%;
  min-width: 768px;
  display: flex;
  align-content: center;
  justify-content: center;
}
.search {
  position: relative;
  display: flex;
  align-items: center;
  width: 1.41rem;
  margin-left: 0.32rem;
  height: 1.06rem;
  z-index: 999;
  .search-input {
    display: block;
    width: 100%;
    height: 0.25rem;
    outline: none;
    border: 0.01rem solid #fff;
    background-color: transparent;
    border-radius: 0.36rem;
    font-size: 0.12rem;
    color: #fff;
    padding: 0 0.3rem 0 0.1rem;
    &::placeholder {
      color: #fff;
    }
  }
  .search-btn {
    position: absolute;
    right: 0.06rem;
    width: 0.15rem;
    cursor: pointer;
  }
}
</style>
