import { createStore } from 'vuex'
import app from './module/app'

const store = createStore({
  state: {
    //
  },
  mutations: {
    //
  },
  actions: {
    //
  },
  modules: {
    app,
  }
})

export default store
