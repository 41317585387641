export default {
  /**
   * @description pc端设计稿宽度
   */
  designPcWidth: 1920,
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: '小镇百科',
  /**
   * @description pc端首页
   */
  pcHome: '/home',
}
