<template>
  <header class="dark">
    <div style="display: flex;cursor: pointer" @click="goPage(`/townsdetail${this.$route.query?.hasOwnProperty('tn')?`?tn=${this.$route.query?.tn}`:''}${this.$route.query?.hasOwnProperty('tid')?`&tid=${this.$route.query?.tid}`:''}`)">
    <img src="/images/common/townIcon.png" class="logo"/>
    <p class="name">{{ router.query.tn }}</p>
    </div>
    <Menu :list="menuList" :active="router.path" :theme="theme" :router="router" :isHome="false"/>
    <div class="right-box" @click="goPage('/home')">
      <img src="/images/common/home.png" class="home"/>
      <p class="home-title">回到门户主页</p>
    </div>
  </header>
</template>
<script>
import {mapGetters} from 'vuex'
import Menu from './menu'


export default {
  name: 'townhader',
  components: {
    Menu
  },
  props: {
    router: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapGetters({
      menuList: 'menuListTown'
    }),
    theme() {
      let path = this.router?.path
      let item = this.menuList.find(item => item.path === path)
      if (item) {
        return item.theme
      } else {
        return 'light'
      }
    }
  },

  methods: {
    goPage (url) {
      this.$router.push(url)
    },

  }



}
</script>
<style lang="scss" scoped>
.dark {

  width: 100%;
  height: 1rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  .right-box {
    display: flex;
    align-items: center;
    cursor: pointer;

    .home-title {
      color:#666666;
      font-size: 0.16rem;
      margin-left: 0.02rem;
    }
  }

}

.name {
  color: #000000;
  font-size: 0.3rem;
  font-weight: 500;
  margin-right: 2.51rem;
}

.light {
  @extend .dark;
  background: #ffffff;
  box-shadow: 0 0.06rem 0.2rem rgba(0, 0, 0, 0.12)
}

.logo {
  width: 0.45rem;
  height: 0.46rem;

}
.home {
  width: 0.19rem;
  height: 0.18rem;
}

</style>
