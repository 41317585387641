import routers from '@/router/routes'
import { getMenuList ,getMenuListTown} from '@/utils/tools'
export default {
  state: {
    loading: false
  },
  getters: {
    menuList: () => getMenuList(routers),
    menuListTown:()=>getMenuListTown(routers),
    loading: state => state.loading
  },
  mutations: {
    save (state, payload) {
      state = Object.assign(state, payload)
    }
  },
  actions: {
    async toggleLoading (ctx, { payload }) {
      ctx.commit('save', {
        loading: payload
      })
    }
  }
}
