const Home = () => import(/* webpackChunkName: "login" */'@/pages/home')
const Album = () => import(/* webpackChunkName: "album" */'@/pages/album')
const Towns = () => import(/* webpackChunkName: "towns" */'@/pages/towns')
const Interview = () => import(/* webpackChunkName: "interview" */'@/pages/interview')
const News = () => import(/* webpackChunkName: "news" */'@/pages/news')
const Vr = () => import(/* webpackChunkName: "vr" */'@/pages/vr')
const Industry = () => import(/* webpackChunkName: "industry" */'@/pages/industry')
const Detail = () => import(/* webpackChunkName: "detail" */'@/pages/detail')
const AlbumDetail = () => import(/* webpackChunkName: "albumdetail" */'@/pages/detail/album')
// const ArticleDetail = () => import(/* webpackChunkName: "articledetail" */'@/pages/detail/article')
const Apply = () => import(/* webpackChunkName: "apply" */'@/pages/apply')
const Activity = () => import('@/pages/activity')
const ActivityDetail = () => import('@/pages/activity/detail')
const InterviewDetail = () => import('@/pages/interview/interview-detail')
const TownsDetail = () => import('@/pages/detail/towndetail')
const Story = () => import('@/pages/story')
const BestTowns = () => import('@/pages/besttowns')
const ArticleDetail = () => import('@/pages/detail/articledetail')
const SignUp = () => import('@/pages/signup')

export default [
  {
    path: '/',
    redirect: '/home',
    meta: {
      hideInMenu: true
    }
  },
  {
    path: '/home',
    component: Home,
    meta: {
      title: '首页',
      theme: 'dark',
    }
  },
  {
    path: '/besttowns',
    component: BestTowns,
    meta: {
      title: '精选小镇',
      theme: 'dark'
    }
  },
  {
    path: '/activity',
    component: Activity,
    meta: {
      title: '小镇活动',
      theme: 'dark'
    }
  },
  {
    path: '/interview',
    component: Interview,
    meta: {
      title: '小镇访谈录',
      theme: 'dark'
    }
  },
  {
    path: '/story',
    component: Story,
    meta: {
      title: '小镇故事',
      theme: 'dark'
    }
  },
  {
    path: '/industry',
    component: Industry,
    meta: {
      title: '小镇产业',
      theme: 'dark'
    }
  },
  {
    path: '/album',
    component: Album,
    meta: {
      title: '小镇相册',
      theme: 'light'
    }
  },

  {
    path: '/news',
    component: News,
    meta: {
      title: '小镇动态',
      theme: 'dark'
    }
  },

  {
    path: '/vr',
    component: Vr,
    meta: {
      title: '小镇VR',
      theme: 'light'
    }
  },
  {
    path: '/detail',
    component: Detail,
    meta: {
      title: '小镇详情',
      theme: 'dark',
      hideInMenu: true
    }
  },
  {
    path: '/albumdetail',
    component: AlbumDetail,
    meta: {
      hideInMenu: true,
      townsInMenu:true,
      title: '小镇相册',
      theme: 'dark'
    }
  },
  {
    path: '/articledetail',
    component: ArticleDetail,
    meta: {
      hideInMenu: true,
      title: '文章详情',
      theme: 'dark'
    }
  },
  {
    path: '/apply',
    component: Apply,
    meta: {
      hideInMenu: true,
      title: '申请入驻',
      theme: 'dark'
    }
  },

  {
    path: '/activitydetail',
    component: ActivityDetail,
    meta: {
      hideInMenu: true,
      title: '小镇活动',
      theme: 'dark'
    }
  },
  {
    path: '/interviewdetail',
    component: InterviewDetail,
    meta: {
      hideInMenu: true,
      title: '小镇访谈',
      theme: 'dark'
    }
  },
  {
    path: '/townsdetail',
    component: TownsDetail,
    meta: {
      hideInMenu: true,
      title: '小镇首页',
      theme: 'dark'
    }
  },
  {
    path: '/towns',
    component: Towns,
    meta: {
      hideInMenu: true,
      title: '寻找小镇',
      theme: 'dark'
    }
  },
  {
    path: '/signup',
    component: SignUp,
    meta: {
      hideInMenu: true,
      title: '赛事报名',
      theme: 'dark'
    }
  },



]
