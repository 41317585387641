import config from '@/config'

export default (router) => {
  router.afterEach((to, from) => {
    document.title = to?.meta?.title ?? config.title
    if (to.path !== from.path) {
      // document.body.scrollTop = 0
      window.scrollTo(0,0)
    }
  })
}
