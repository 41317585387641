<template>
  <li
    v-if="!item.isHide"
    class="light"
    @click="goPage(item.path)"
    :style="isActive?{color:'#125C9D','font-weight':700}:{color:'#333333','font-weight':400}"
  >
    {{ item.title }}
  </li>
</template>
<script>




export default {
  name: '',
  components: {},
  props: {
    theme: {
      type: String,
      default: 'light'
    },
    isActive: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => { }
    },
    router:Object,
    isHome: {
      type:Boolean,
      default:true
    }
  },
  methods: {
    goPage (url) {

      this.$router.push({
        path:url,
        query: this.isHome?{}:this.$route.query
      })


    }
  },

}
</script>
<style lang="scss" scoped>
.dark {
  line-height: 1.06rem;
  color: #ffffff;
  font-size: 0.16rem;
  margin: 0 0.32rem;
  cursor: pointer;
}
.light {
  line-height: 1.06rem;
  color: #333333;
  font-size: 0.16rem;
  margin: 0 0.32rem;
  cursor: pointer;
  display: flex;
  position: relative;
  //background: yellowgreen;

  align-items: center;
  &:not(:last-child) {
    &::after {
      width: 0.02rem;
      height: 0.13rem;
      position: absolute;
      background: #E1E1E1;
      right: -0.35rem;
      content: '';
    }
  }
}
.active {
}
</style>
