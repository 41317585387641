import dayjs from 'dayjs'

/**
 * 调整html元素font-size
 * @param {设计稿宽度} designWidth
 * @param {最小宽度} minWidth
 * @param {最大宽度} maxWidth
 */
export const adaptHtmlSize = (designWidth, minWidth = 320, maxWidth = 2080) => {
  let screenWidth = window.innerWidth
  if (maxWidth && maxWidth < screenWidth) {
    screenWidth = maxWidth
  }
  if (minWidth > screenWidth) {
    screenWidth = minWidth
  }
  let htmlSize = screenWidth / designWidth * 100
  document.documentElement.style.fontSize = `${htmlSize}px`
  document.body.style.fontSize = '16px'
  document.body.style.minWidth = `${minWidth}px`
}

/**
 * 从路由中获取菜单列表
 * @param {配置路由数组} routes
 */
export const getMenuList = routes => {
  return routes.map(item => {
    let menu = {
      path: item.path,
      title: item?.meta.title,
      icon: item?.meta.icon,
      theme: item?.meta.theme,
      isHide: item?.meta?.hideInMenu,
      children: []
    }
    if (item.children && item.children.length) {
      menu.children = getMenuList(item.children)
    }
    return menu
  })
}
/**
 * 从路由中获取菜单列表
 * @param {配置路由数组} routes
 */
export const getMenuListTown = routes => {


  let r1 = findPage('/albumdetail',routes);
  let r2 = findPage('/interviewdetail',routes);
  let r3 = findPage('/news',routes);
  let r4 = findPage('/industry',routes);
  let r5 = findPage('/story',routes);
  return [
    {
      path: routes[r1].path,
      title: routes[r1]?.meta.title,
    },
    {
      path: routes[r2].path,
      title: routes[r2]?.meta.title,
    },
    {
      path: routes[r3].path,
      title: routes[r3]?.meta.title,
    },
    {
      path: routes[r4].path,
      title: routes[r4]?.meta.title,
    },
    {
      path: routes[r5].path,
      title: routes[r5]?.meta.title,
    },
  ]

}

export const findPage = (url,route) =>{
  let index = 0
  for (let i = 0;i < route.length;i++){
    if (route[i].path == url){
      index = i;
      break
    }
  }
  return index;
}


/**
 * 判断是否为移动端
 */
export const isMobile = () => {
  let info = navigator.userAgent
  let agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPod', 'iPad']
  for (let i = 0; i < agents.length; i++) {
    if (info.includes(agents[i])) {
      return true
    }
  }
  return false
}

/**
 * 节流
 * @param {回调函数} fn
 * @param {间隔时间} timeout
 */
export const throttle = (fn, timeout) => {
  let lastTime = 0
  return function (...args) {
    let nowTime = new Date().getTime()
    if (nowTime - lastTime >= timeout) {
      lastTime = nowTime
      fn.apply(this, args)
    }
  }
}

/**
 * 防抖
 * @param {回调函数} fn
 * @param {沉默时间} delay
 * @param {立即执行} immediate
 */
export const debounce = (fn, delay, immediate = true) => {
  let timeout
  return function (...args) {
    if (timeout) clearTimeout(timeout)
    if (immediate) {
      // 如果已经执行过，不再执行
      let callNow = !timeout
      timeout = setTimeout(() => {
        timeout = null
      }, delay)
      if (callNow) fn.apply(this, args)
    } else {
      timeout = setTimeout(() => {
        fn.apply(this, args)
      }, delay)
    }
  }
}
/**
 * 视频全屏
 * @param {video dom} videoDom
 */
export const toFullVideo = (videoDom) => {
  if (videoDom.requestFullscreen) {
    return videoDom.requestFullscreen()
  } else if (videoDom.webkitRequestFullScreen) {
    return videoDom.webkitRequestFullScreen()
  } else if (videoDom.mozRequestFullScreen) {
    return videoDom.mozRequestFullScreen()
  } else {
    return videoDom.msRequestFullscreen()
  }
}

/**
 * 截取html中的文字
 * @param {html} content
 */
export const hideTextTag = (content) => {
  let text = ''
  if (content) {
    text = content.replace(/<[^>]+>|&nbsp;/g, '')
  }
  return text
}

/**
 * 格式化日期
 * @param {日期} date
 * @param {格式} format
 */
export const formatDate = (date, format = 'YYYY-MM-DD') => {
  return dayjs(new Date(date)).format(format)
}




