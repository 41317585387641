const { valueOf } = require('core-js/fn/_')
<template>
  <div v-if=" router.meta?.title !== '赛事报名'">
  <TownHeader :router="router" v-if="router.query?.hasOwnProperty('tn') || router.meta?.title == '小镇首页'"/>
  <Header :router="router" v-else/>
</div>
  <router-view style="padding-bottom: 0.6rem"></router-view>
  <Copyright style="position: fixed;bottom: 0; z-index: 999" v-if=" router.meta?.title !== '赛事报名'"/>

  <a-modal v-bind:visible="codeVisible" :footer="null" :bodyStyle="{ padding: 0 }"
  @cancel="modelCancelClick"
  >
    <div class="model-box">
      <img src="/images/activity/townqrcode.png" class="qrcode-img"/>
      <span class="text" style="margin-bottom: 0.14rem">微信扫描二维码参加活动</span>
    </div>
  </a-modal>

</template>
<script>
import Header from '@/components/layout/header'
import TownHeader from '@/components/layout/header/town-header'
import Copyright from '@/components/common/copyright'

export default {
  name: '',
  components: {
    Header,
    TownHeader,
    Copyright
  },
  data() {
    return {
      router: {},
      codeVisible:false,
    }
  },
  watch: {
    $route(newer) {
      this.router = newer
    }
  },
  created () {
    let that = this;
    window.addEventListener('message',function (e){
      if (e.data?.m_name === 'showQrCode'){
        that.codeVisible= true;
      }

    })
  },
  methods:{
    modelCancelClick(){
      this.codeVisible = false;
    }
  }

}
</script>
<style lang="scss" scoped>
.model-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .qrcode-img {
    width: 1.44rem;
    height: 1.44rem;
    margin: 0.2rem 0;
  }
}

</style>
