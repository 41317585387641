import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import routerAfter from './router-after'

const router = createRouter({
  history: createWebHistory(),
  routes,

})

routerAfter(router)


export default router
