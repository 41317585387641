<template>
  <header class="dark">
    <img src="/images/home/logo.png" class="logo"/>
    <Menu :list="menuList" :active="router.path" :theme="theme"/>
  </header>
</template>
<script>
import {mapGetters} from 'vuex'
import Menu from './menu'

export default {
  name: '',
  components: {
    Menu
  },
  props: {
    router: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapGetters({
      menuList: 'menuList'
    }),
    theme() {
      let path = this.router?.path
      let item = this.menuList.find(item => item.path === path)
      if (item) {
        return item.theme
      } else {
        return 'light'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dark {
  //position: absolute;
  //left: 0;
  //top: 0;
  //z-index: 999;
  width: 100%;
  height: 1rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

}

.light {
  @extend .dark;
  background: #ffffff;
  box-shadow: 0 0.06rem 0.2rem rgba(0, 0, 0, 0.12)
}

.logo {
  width: 1.1rem;
  height: 0.46rem;
  margin-right: 2.51rem;
}
</style>
