<template>
  <div class="wrap">
    <div>CopyRight ©2000-2022 sc.news.cn All Rights Reserved.</div>
    <div>制作单位：成都云智天下科技股份有限公司 版权所有：新华网股份有限公司</div>

  </div>
</template>
<script>
export default {
  name: 'copyright',
}
</script>
<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 0.6rem;
  background: #01398D;
  font-size: 0.12rem;
  color: #4B72AC;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
</style>
